import { EOrderType } from '../../utils/types/order';
import './style.scss';

const BADGETYPES = [
  { id: 0, badgeType: EOrderType.ONE_WAY, title: '편도운송' },
  { id: 1, badgeType: EOrderType.ROUND_TRIP, title: '왕복운송' },
  { id: 2, badgeType: 'isCash', title: '선착불' },
]

export default function TransportMethodBadge({
  additionalClass,
  badgeType,
}: {
  additionalClass?: any;
  badgeType: EOrderType | 'isCash';
}) {
  return (
    <span className={`transport-method-badge ${badgeType} ${additionalClass}`}>
      {BADGETYPES.find((el) => el.badgeType === badgeType)?.title}
    </span>
  );
}
