import { Button, Form, FormInstance, Input, Progress, Radio, Space } from "antd";
import { useWatch } from "antd/lib/form/Form";
import location from "@/assets/images/kakaoMapIcon.svg";
import cx from "classnames";
import numeral from "numeral";
import { ChangeEvent, useState } from "react";
import { MIN_DISCOUNT_FREIGHT_COST } from "@/utils/constants";
// import { useCallbackPrompt } from "@/hooks/useCallbackPrompt";

interface Props {
  form: FormInstance<any>;
  onBack?: () => void;
  loading?: boolean;
  method?: string | "one-way" | "two-way";
  loadingPlace?: boolean;
}

export default function Step4({ form, onBack, loading, method, loadingPlace = false }: Props) {
  const routeUrl = useWatch("routeUrl", form);
  const radioValue = useWatch("isFastShipping", form);
  const freightCost = useWatch("freightCost", form);
  const totalMoney = useWatch("totalMoney", form);
  const [isEditingFreightCost, setIsEditingFreightCost] = useState<boolean>(false);

  const [warning, setWarning] = useState(false);
  // const [isBlocking, setIsBlocking] = useState(true);

  // if (useCallbackPrompt) {
  //   useCallbackPrompt(isBlocking);
  // }

  // useEffect(() => {
  //   if (!!freightCost && totalMoney) {
  //     const generalFee = numeral(freightCost).value() ?? 0;
  //     if (totalMoney - generalFee > 20000) {
  //       setWarning(true);
  //     } else {
  //       setWarning(false);
  //     }
  //   }
  // }, [freightCost, totalMoney]);

  /* ------------------------------- 이전 페이지로 복귀 ------------------------------- */
  const handleBackStep = () => {
    onBack?.();
  };

  const handleReset = () => {
    form.resetFields(["routeUrl", "totalMoney"]);
    form.setFieldsValue({
      totalMoney: 0,
      freightCost: 0,
      fastShipping: 0,
      estimatedDistance: 0,
      isFastShipping: false,
      isCash: false
    });
  };

  const changeFreightCostHandler = (event: ChangeEvent<HTMLInputElement>) => {
    setIsEditingFreightCost(true);
    const newValue = numeral(event.target.value).format("0,00");
    form.setFieldValue("freightCost", newValue);
  };

  const applyFreightCostChange = () => {
    let freightCost = numeral(form.getFieldValue("freightCost")).value() || 0;
    const totalMoney = numeral(form.getFieldValue("totalMoney")).value() || 0;
    if (totalMoney - freightCost > MIN_DISCOUNT_FREIGHT_COST) {
      freightCost = totalMoney - MIN_DISCOUNT_FREIGHT_COST;
    }

    if (freightCost < MIN_DISCOUNT_FREIGHT_COST) {
      freightCost = MIN_DISCOUNT_FREIGHT_COST;
    }
    form.setFieldValue("freightCost", numeral(freightCost).format("0,00"));

    // set default fast shipping
    if (freightCost < 300000) {
      let fastFee = freightCost + 30000;
      form.setFieldValue("fastShipping", numeral(fastFee).format("0,00"));
    } else {
      let fastFee = freightCost + 50000;
      form.setFieldValue("fastShipping", numeral(fastFee).format("0,00"));
    }
    setIsEditingFreightCost(false);
  };

  return (
    <div className={cx("step-first-dispatch step-4 pb-[50px] mt-[80px]", method === "two-way" ? "" : "divider-step")}>
      {/* <div className="flex justify-between items-center">
        <Space direction="horizontal" size={16}>
          <span className="text-primary-brandblue font-bold text-2xl capitalize">STEP 4</span>
          <span className="text-[#131416] text-2xl capitalize font-bold">상세 경로 </span>
        </Space>

        <Button
          onClick={handleReset}
          className="border-[1.2px] border-solid border-[#DFE0E2] inline-flex justify-center items-center bg-white rounded-lg py-2 px-5"
        >
          초기화
        </Button>
      </div> */}
      {/* <div className="my-6">
        <Progress percent={100} strokeWidth={2} strokeColor="#5d98d1" showInfo={false} />
      </div> */}
      {method === "two-way" && (
        <div className="text-sm mb-4 font-normal">
          <span className="text-[#E21B1B]">*</span>왕복 운임은 편도 운임의 2배로 책정되며, 경로는 최적화된 경로를
          보여주며 실제와 다를수도 있습니다.
        </div>
      )}
      <div className="grid grid-cols-12 ">
        <div className="col-span-6 col-end-6">
          <Form.Item
            label="예상 경로보기"
            labelCol={{
              span: 5,
              offset: 0,
            }}
            wrapperCol={{
              span: 17,
              offset: 1,
            }}
          >
            <Button
              block
              className="flex items-center justify-center h-[50px] bg-[#FBE300] rounded-lg"
              icon={<img src={location} alt="location" />}
              disabled={!routeUrl}
              onClick={() => {
                if (routeUrl) {
                  window.open(
                    routeUrl,
                    "Dispatch detail",
                    "width=1000,height=700,top=100,left=200,status=1,titlebar=1,toolbar=1,menubar=1",
                  );
                }
              }}
            >
              카카오 맵 길찾기
            </Button>
          </Form.Item>
          <Form.Item
            label="예상 거리"
            labelCol={{
              span: 5,
              offset: 0,
            }}
            wrapperCol={{
              span: 17,
              offset: 1,
            }}
            name="estimatedDistance"
          >
            <Input className="rounded-lg border-[1.2px] border-solid bg-white h-[50px]" suffix={"km"} disabled={true} />
          </Form.Item>
          <Form.Item label="" name="routeUrl">
            <Input className="rounded-lg border-[1.2px] border-solid bg-white h-[50px]" hidden />
          </Form.Item>
        </div>
        <div className="col-span-6 col-start-8">
          <div className="flex items-start space-x-3">
            <Form.Item noStyle name="isFastShipping">
              <Radio.Group>
                <Space direction="vertical" className="w-[100px] h-full flex flex-col gap-6">
                  <Radio value={false} className="flex gap-y-10 h-[50px]">
                    <div className="flex items-start space-x-4">
                      <div className="flex flex-col items-center">
                        일반 운송
                        {method === "two-way" && <span className="text-[#017CFF]">(왕복)</span>}
                      </div>
                    </div>
                  </Radio>
                  <Radio value={true} className="">
                    <div className="flex items-start space-x-4">
                      <div className="flex flex-col items-center">
                        빠른 운송
                        {method === "two-way" && <span className="text-[#017CFF]">(왕복)</span>}
                      </div>
                    </div>
                  </Radio>
                </Space>
              </Radio.Group>
            </Form.Item>
            <div className="flex flex-col gap-6 relative">
              <Form.Item noStyle name="totalMoney">
                <Input hidden className="h-[48px]" />
              </Form.Item>
              <span
                className={`absolute right-0 top-[-25px] cursor-pointer text-[${isEditingFreightCost ? "#1F67AD" : "#A9ADB2"
                  }]`}
                onClick={applyFreightCostChange}
              >
                {isEditingFreightCost ? "완료" : "수정"}
              </span>
              <Form.Item noStyle name="freightCost">
                <Input
                  onChange={(event: ChangeEvent<HTMLInputElement>) => changeFreightCostHandler(event)}
                  disabled={radioValue}
                  suffix="원"
                  className="rounded-lg border-[1.2px] bg-white h-[50px] disabled:text-black"
                />
              </Form.Item>
              <Form.Item noStyle name="fastShipping">
                <Input
                  className={cx(
                    "rounded-lg border-[1.2px] bg-white h-[50px] disabled:text-black",
                    radioValue ? "fastShipping" : "",
                  )}
                  suffix="원"
                  disabled={true}
                  onChange={(event) => {
                    const value = event.target.value;

                    form.setFieldValue("fastShipping", numeral(value).format("0,00"));
                  }}
                />
              </Form.Item>
              {/* <span
                className={cx(
                  "text-sm text-nowrap",
                  method === "two-way" ? "text-[#8D939A] mt-[-8px]" : "text-[#FF5C5C]",
                )}
              >
                {method !== "two-way"
                  ? "*운임 결제 시 부가세와 산재보험료가 추가됩니다."
                  : "운임 결제 시 부가세와 산재보험료가 추가됩니다."}
              </span> */}
              {!!warning && <span className="text-sm text-[#FF5C5C] block mt-1">잘못된 금액 변경</span>}
            </div>
            <Form.Item noStyle name="isCash">
              <Radio.Group >
                <Space direction="horizontal" className="flex gap-44">
                  <div className="flex items-center">
                    <Radio value={false} className="ml-2 px-5 py-3 border border-solid border-primary-brandblue rounded-lg w-[280px]">
                      <h3>배차킹 결제</h3>
                      <h3>(카드/계좌이체/PAY)</h3>
                    </Radio>
                  </div>
                  <div className="flex items-center">
                    <Radio value={true} className="ml-1 px-5 py-3 border border-solid border-primary-brandblue rounded-lg w-[280px]">
                      <h3>선착불</h3>
                      <h3>(기사님에게 직접 지불)</h3>
                    </Radio>
                  </div>
                </Space>
              </Radio.Group>
            </Form.Item>
          </div>
        </div>
      </div>
      <div className="flex items-center justify-center space-x-9">
        <Button
          className="border border-solid border-primary-brandblue text-xl bg-white rounded-lg h-[64px] max-w-[300px] text-xl font-bold leading-8 text-[#5D98D1]"
          block
          onClick={handleBackStep}
        >
          이전
        </Button>
        <Form.Item noStyle shouldUpdate>
          {({ getFieldsValue, getFieldValue }) => {
            const data = getFieldsValue(["freightCost", "startDate", "endDate", "estimatedDistance"]);

            const isEmptyValue = Object.keys(data).filter((key) => !data[key]);
            let km = getFieldValue("estimatedDistance");
            let invalidStartTime = !getFieldValue("isPickUpImmediately") && !getFieldValue("startTime");
            let invalidEndTime = !getFieldValue("isDropDownImmediately") && !getFieldValue("endTime");
            const inValidForm =
              isEmptyValue.length > 0 ||
              invalidStartTime ||
              invalidEndTime ||
              km === "0.00" ||
              warning ||
              isEditingFreightCost ||
              form.getFieldsError().filter(({ errors }) => errors.length).length > 0 ||
              loadingPlace;

            return (
              <Button
                block
                loading={loading}
                className={cx("text-white rounded-lg h-[64px] max-w-[300px] text-xl font-bold leading-8", {
                  "bg-primary-brandblue": !inValidForm,
                  "bg-[#D9D9D9]": inValidForm,
                })}
                disabled={inValidForm}
                htmlType="submit"
              >
                배차 수정
              </Button>
            );
          }}
        </Form.Item>
      </div>
    </div>
  );
}
