import { Button, Form, Input } from "antd";
import "./style.scss";
import icClose from "@/assets/images/ic_close.svg";
import { useForm } from "antd/lib/form/Form";
import { useState } from "react";
import { useCancelOrderMutation } from "@/pages/DispatchManagement/dispatchApi";
import { useDispatch } from "react-redux";
import { openDialog } from "@/components/customDialog/dialogSlice";
import { closeModal } from "../../modalSlice";


const CancelOrder = ({ data, onClose }: any) => {
    const [loadingPayment, setloadingPayment] = useState(false);
    const [form] = useForm();
    const carrierReturnFeeWatch = Form.useWatch("carrierReturnFee", form);
    const carrierReturnReasonWatch = Form.useWatch("carrierReturnReason", form);
    const [cancelOrder] = useCancelOrderMutation();
    const dispatch = useDispatch<any>();
    const isActive = (carrierReturnFeeWatch?.length > 0) && carrierReturnReasonWatch?.length > 0

    const handleCancelOrder = async () => {
        setloadingPayment(true)

        const reason = form.getFieldValue("carrierReturnReason");
        const compensationFee = form.getFieldValue("carrierReturnFee") ? parseInt(form.getFieldValue("carrierReturnFee")) : 0;
        const compensationVat = Math.round(compensationFee * 0.1) || 0;
        const params = {
            id: data?.id,
            compensationVat,
            compensationFee,
            reason
        }
        await cancelOrder(params)
            .unwrap()
            .then((res) => {
                setloadingPayment(false);

                dispatch(
                    openDialog({
                        type: "info",
                        content: "취소되었습니다.",
                        confirmText: "확인",
                        actionConfirm: () => {
                            onClose();
                        },
                    }),
                );
            })
            .catch((error) => {
                setloadingPayment(false);

                dispatch(
                    openDialog({
                        type: "info",
                        content: "Error",
                        confirmText: "확인",
                        actionConfirm: () => {
                            dispatch(closeModal());
                        },
                    }),
                );
            });
    }

    return (
        <div className="update-memo-wrapper">
            <div className="header-wrapper">
                <div className="title">배차 취소</div>
                <div className="close-btn" onClick={() => onClose()}>
                    <img className="ic-close" alt="Close" src={icClose} />
                </div>
            </div>
            <div className="content-wrapper">
                <Form
                    onFinish={handleCancelOrder}
                    layout="vertical"
                    form={form}
                    labelAlign="left"
                    initialValues={{
                        carrierReturnFee: '',
                        carrierReturnReason: "",
                    }}
                >
                    <Form.Item name="carrierReturnFee" label="회차료" className="mb-[28px]">
                        <Input
                            placeholder="회차료"
                            style={{
                                width: '200px',
                                height: '50px',
                                borderRadius: '8px',
                                border: '1px solid #DFE0E2'
                            }}
                            minLength={1}
                            maxLength={15}
                            suffix="원"
                            onKeyDown={(event) => {
                                if (!/[0-9.]/.test(event.key) && event.code !== "Backspace") {
                                    event.preventDefault();
                                }
                            }}
                        />
                    </Form.Item>
                    <Form.Item name="carrierReturnReason" label="회차사유" className="mb-[28px]">
                        <Input.TextArea
                            style={{
                                width: '100%',
                                minHeight: '151px',
                                borderRadius: '8px',
                                border: '1px solid #DFE0E2'
                            }}
                            placeholder="사유는 운송 기사님에게 보여집니다."
                            maxLength={300}
                        />
                    </Form.Item>
                    <Form.Item>
                        <Button
                            style={{
                                width: '220px',
                                margin: 'auto',
                                height: '52px',
                                cursor: 'pointer',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                padding: '16px 0',
                                borderRadius: '4px',
                                color: 'white',
                                fontWeight: 600,
                                border: 'none',
                                backgroundColor:
                                    isActive
                                        ? '#5d98d1'
                                        : '#C4C7CA',
                            }}
                            loading={loadingPayment}
                            htmlType="submit"
                            disabled={!isActive || loadingPayment}
                        >
                            배차취소
                        </Button>
                    </Form.Item>
                </Form>
            </div>
        </div>
    );
};

export default CancelOrder;