import { FC } from "react";
import { useTranslation } from "react-i18next";
import "./style.scss";
import { downloadFile, formatPhoneNumber, formatDuration, formatMoney } from "../../utils/utils";
import TransportMethodBadge from "../transportMethod";
import moment from "moment-timezone";
import { calculateRows } from "@/utils/commonFnc";


interface Props {
  data: any;
  showContactInfo?: boolean;
  fromPage?: "MATCHING_MANAGEMENT" | string;
  showCarrierInfo?: boolean;
  isShowBadge?: boolean;
  isCancelDispatch?: boolean;
}

export const ShippingInfo: FC<Props> = ({
  data,
  showContactInfo,
  fromPage,
  showCarrierInfo = false,
  isShowBadge = false,
  isCancelDispatch = false,
}) => {
  const { t } = useTranslation();

  let transitTime = 0;
  if (moment(data?.actualStartDate).isValid() && moment(data?.actualEndDate).isValid()) {
    transitTime = moment(data?.actualEndDate).diff(moment(data?.actualStartDate), "seconds");
  }

  const isMP = fromPage === "MATCHING_MANAGEMENT";

  const carrierInfo = typeof data?.carrierInfo === "string" ? JSON?.parse(data?.carrierInfo) : data?.carrierInfo;

  return (
    <div className="container-wrap">
      <div className="card-header">
        <div className="header-title">{t("shipping_information")}</div>
        <div className="transport-method">
          <TransportMethodBadge badgeType={data?.orderType} additionalClass={"ml-16"} />
          {
            data?.isCash &&
            <TransportMethodBadge badgeType={'isCash'} additionalClass={"ml-16"} />
          }
        </div>
        {isShowBadge && isCancelDispatch && <div className="header-badge">회차료</div>}
      </div>
      <div className="card-content">
        {showCarrierInfo && (
          <>
            <div className="info-section">
              <div className="column-info-custom">
                <div className="column-info-inner">
                  <div className="item-column">
                    <div className="item-sub fw-700">
                      {" "}
                      <span className="text-bold">화주 등록 운임</span>: {formatMoney(data?.freightCost)}원
                    </div>
                  </div>
                  <div className="item-column">
                    <div className="item-sub">
                      <span className="text-bold">배차킹 수수료</span>:{" "}
                      {formatMoney(data?.freightCost - (data?.carrierPaymentAmount - data?.carrierOtherFee))}원
                    </div>
                  </div>
                </div>
                <div className="column-info-inner">
                  <div className="item-column">
                    <div className="item-sub">
                      운송인 앱 노출 금액: {formatMoney(data?.carrierPaymentAmount - data?.carrierOtherFee)}원
                    </div>
                  </div>
                  <div className="item-column">
                    <div className="item-sub">추가 결제 금액: {formatMoney(data?.otherFee)}원</div>
                  </div>
                </div>
              </div>
            </div>
            <div className="info-section">
              <div className="column-info-custom">
                <div className="column-info-inner">
                  <div className="item-column">
                    <div className="item-sub">
                      운송인 부가세: {formatMoney(Math.round(data?.carrierPaymentAmount * 0.1))}원
                    </div>
                  </div>
                  <div className="item-column">
                    <div className="iitem-sub ">
                      운송인 산재보험료 금액: {formatMoney(data?.carrierPaymentAmountInsurance)}원
                    </div>
                  </div>
                </div>
                <div className="column-info-inner">
                  <div className="item-column">
                    <div className="item-sub fw-700">
                      세금 계산서 금액: {formatMoney(data?.carrierPaymentAmountTax)}원
                    </div>
                  </div>
                  <div className="item-column">
                    <div className="item-sub fw-700">
                      정산 요청 금액: {formatMoney(data?.carrierSettlementAmount)}원
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
        <div className="info-section-wrapper">
          <div className="info-section-expand">
            <div className="column-info-horizontal">
              <div className="item-column">
                <div className="item-title">{t("loading_address")}</div>
                <div className="item-sub">{data?.loadingPlace} {data?.detailLoadingPlace}</div>
              </div>
              {data?.transitPlaces &&
                data?.transitPlaces?.length > 0 &&
                data?.transitPlaces.map((it: any, index: number) => {
                  return (
                    <div key={index} className="item-column mt-10">
                      <div className="item-title">상차지 경유지 주소</div>
                      <div className="item-sub">{it?.fullAddress} {it?.detailAddress}</div>
                    </div>
                  );
                })}
            </div>
            <div className="column-info-horizontal">
              <div className="item-column">
                <div className="item-title">{t("unloading_address")}</div>
                <div className="item-sub">{data?.receiptPlace} {data?.detailReceiptPlace}</div>
              </div>
              {data?.additionalEndpoints &&
                data?.additionalEndpoints?.length > 0 &&
                data?.additionalEndpoints.map((it: any, index: number) => {
                  return (
                    <div key={index} className="item-column mt-10">
                      <div className="item-title">하차지 경유지 주소</div>
                      <div className="item-sub">{it?.fullAddress} {it?.detailAddress}</div>
                    </div>
                  );
                })}
            </div>
            <div className="column-info-horizontal"></div>
          </div>
          {isMP && (
            <div className="info-expand-wrapper">
              <div className="item-column item-column-expand">
                <div className="item-title">담당자 연락처</div>
                <div className="item-sub"> {formatPhoneNumber(data?.contactSender)}</div>
              </div>
              <div className="item-column item-column-expand">
                <div className="item-title">담당자 연락처</div>
                <div className="item-sub"> {formatPhoneNumber(data?.contactReceiver)}</div>
              </div>
              <div className="item-column item-column-expand">
                <div className="item-title">화주 연락처</div>
                <div className="item-sub">
                  {formatPhoneNumber(
                    data?.provider?.type === "NORMAL" ? data?.provider?.phoneContact : data?.provider?.picPhone,
                  )}
                </div>
              </div>
            </div>
          )}
        </div>

        <div className="info-section">
          <div className="column-info w-20">
            <div className="item-column">
              <div className="item-title">{t("goods_type")}</div>
              <div className="item-sub">{data?.type}</div>
            </div>
            <div className="item-column">
              <div className="item-title">{t("goods_docs")}</div>
              {data?.docUrls &&
                data?.docUrls.map((it: any, index: number) => {
                  return (
                    <div key={index} onClick={() => downloadFile(it.url, it.name)} className="item-sub fileName">
                      {it.name}
                    </div>
                  );
                })}
            </div>
          </div>
          <div className="column-info w-20">
            <div className="item-column">
              <div className="item-title">{t("상하차 방법")}</div>
              <div className="item-sub">{data?.info}</div>
            </div>
            {isMP && (
              <div className="item-column">
                <div className="item-title">팩스번호</div>
                <div className="item-sub">{data?.fax}</div>
              </div>
            )}
          </div>
          <div className="column-info w-20">
            <div className="item-column">
              <div className="item-title">{t("goods_quantity")}</div>
              <div className="item-sub">{data?.quantity}</div>
            </div>
            {isMP && (
              <div className="item-column">
                <div className="item-title">차량 톤수/차종</div>
                <div className="item-sub">
                  {data?.tonnage}/{data?.vehicleType}
                </div>
              </div>
            )}
          </div>
          <div className="column-info w-20">
            <div className="item-column">
              <div className="item-title">{t("packaging_method")}</div>
              <div className="item-sub">{data?.packingMethod}</div>
            </div>
            {isMP && (
              <div className="item-column">
                <div className="item-title">혼적여부</div>
                <div className="item-sub">{data?.isCombineGoods ? `해당 / ${data?.combineGoods}` : "비해당"}</div>
              </div>
            )}
          </div>
          <div className="column-info w-20">
            <div className="item-column">
              <div className="item-title">요청 사항</div>
              <div className="item-sub">{data?.requirement}</div>
            </div>
          </div>
        </div>

        <div className="info-section">
          <div className="column-info w-40">
            <div className="item-column">
              <div className="item-title">{t("estimated_loading_time")}</div>
              <div className="item-sub">
                {data?.isPickUpImmediately ? data?.expectedStartDate + " 바로상차" : data?.expectedStartDate}
              </div>
            </div>
            <div className="item-column">
              <div className="item-title">{t("actual_loading_time")}</div>
              <div className="item-sub">{data?.actualStartDate}</div>
            </div>
          </div>
          <div className="column-info w-40">
            <div className="item-column">
              <div className="item-title">{t("estimated_unloading_time")}</div>
              <div className="item-sub">
                {data?.isDropDownImmediately ? data?.expectedEndDate + " 바로하차" : data?.expectedEndDate}
              </div>
            </div>
            <div className="item-column">
              <div className="item-title">{t("actual_unloading_time")}</div>
              <div className="item-sub">{data?.actualEndDate}</div>
            </div>
          </div>

          <div className="column-info w-20 transport-method">
            <TransportMethodBadge badgeType={data?.orderType} additionalClass={"large"} />
          </div>
        </div>
        <div className="info-section">
          <div className="column-info w-40">
            <div className="item-column">
              <div className="item-title">관리자 메모</div>
              <textarea
                rows={calculateRows(data?.notes || '')}
                className="item-sub"
                value={data?.notes || ''}
              />
            </div>
          </div>
        </div>
        <div className="info-section">
          <div style={{ display: "flex", flexDirection: "column", width: "100%" }}>
            {fromPage === "MATCHING_MANAGEMENT" && (
              <div className="sub-section">
                <div className="column-info w-20">
                  <div className="item-column">
                    <div className="item-title">운송인 이름</div>
                    <div className="item-sub">{data?.carrier?.name}</div>
                  </div>
                </div>
                <div className="column-info w-20">
                  <div className="item-column">
                    <div className="item-title">전화번호</div>
                    <div className="item-sub">{formatPhoneNumber(data?.carrier?.phone)}</div>
                  </div>
                </div>
                {isMP && (
                  <div className="column-info w-20">
                    <div className="item-column">
                      <div className="item-title">운송인 차량번호</div>
                      <div className="item-sub">
                        {data?.carrier?.region} {data?.carrier?.licensePlate}
                      </div>
                    </div>
                  </div>
                )}
              </div>
            )}

            <div className="main-section">
              <div className="column-info w-20">
                <div className="item-column">
                  <div className="item-title">{t("transport_vehicle")}</div>
                  <div className="item-sub">
                    {isMP ? (carrierInfo ? data?.carrier?.tonnage : carrierInfo?.tonnage) : data?.tonnage}/
                    {isMP ? (carrierInfo ? data?.carrier?.vehicleType : carrierInfo?.vehicleType) : data?.vehicleType}
                  </div>
                </div>
              </div>
              <div className="column-info w-20">
                <div className="item-column">
                  <div className="item-title">{t("transit_time")}</div>
                  <div className="item-sub">{formatDuration(data?.estimatedTravelTime || transitTime)}</div>
                </div>
              </div>
              <div className="column-info w-20">
                <div className="item-column">
                  <div className="item-title">{t("transit_distance")}</div>
                  <div className="item-sub">{data?.estimatedDistance} km</div>
                </div>
              </div>
              {showContactInfo && (
                <div className="column-info w-20">
                  <div className="item-column">
                    <div className="item-title">{t("contact")}</div>
                    <div className="item-sub">{formatPhoneNumber(data?.carrier?.phone)}</div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

