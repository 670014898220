import "./style.scss";
import { ColumnsType } from "antd/lib/table";
import { useEffect, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import moment from "moment";
import icMap from "../../assets/images/ic_map.svg";
import { closeModal, openModal } from "../../components/customModal/modalSlice";
import { openDialog } from "../../components/customDialog/dialogSlice";
import { downloadFile, formatMoney, truncateFileName } from "../../utils/utils";
import { SEARCH_ORDER, PER_PAGE, STATUS_WAITING_MATCHING, STATUS_WAITING_APPROVAL, STATUS_CANCEL } from "../../utils/constants";
import TransportMethodBadge from "../../components/transportMethod";
import {
    calculateRows,
    formatDelay,
    formatPhoneNumber,
    getDateFromData,
    getDateToData,
    getUrlParams,
    wait,
} from "../../utils/commonFnc";
import NewTable from "../../components/NewTable";
import { useLocation, useNavigate } from "react-router-dom";
import { Divider } from "antd";
import { useCancelOrderMutation, useGetOrdersQuery } from "@/pages/DispatchManagement/dispatchApi";
import { useUpdateDispatchMutation } from "@/pages/DispatchModify/VehicleRegisApi";
import { useGetOrderDetailQuery } from "@/pages/SettlementManagement/settlementApi";
import { DataType } from "@/pages/DispatchManagement";

const SEARCH_OR_STATUS = `searchOr={"status":"${STATUS_CANCEL}"}&`;

/* ---------------------------------- 타입 선택 --------------------------------- */
const typeOptions = {
    ALL: { label: "전체", value: "all" },
    NORMAL: { label: "일반회원", value: "NORMAL" },
    BUSINESS: { label: "기업회원", value: "BUSINESS" },
    PAY_LATER: { label: "후불회원", value: "PAY_LATER" },
};

export default function PageLayout() {
    const [memoText, setMemoText] = useState<string>('');
    const dispatch = useDispatch<any>();
    const [cancelOrder] = useCancelOrderMutation();
    const { t } = useTranslation();
    const [updateDispatch] = useUpdateDispatchMutation();
    const navigate = useNavigate();
    const idDispatch = getUrlParams("id");
    const [isShowDetail, setIsShowDetail] = useState(!!idDispatch);
    const [isMemoEditing, setIsMemoEditing] = useState(true);
    const location = useLocation();

    const { data: infoDetail, refetch: handleRefreshDetail, isSuccess: isSuccessDetailOrder } = useGetOrderDetailQuery(
        { id: idDispatch },
        {
            refetchOnMountOrArgChange: true,
            skip: !idDispatch,
        },
    );

    useEffect(() => {
        if (!!idDispatch) {
            setIsShowDetail(true);
        } else {
            setIsShowDetail(false);
            window.scrollTo(0, 0);
        }

        return () => { };
    }, [location]);

    const [metaData, setMetaData] = useState<any>({});
    const [filters, setFilters] = useState<string[]>(["representationName"]);
    const [params, setParams] = useState({
        search: "",
        page: 1,
        limit: PER_PAGE * 2,
        startDate: null,
        status: "all",
        endDate: null,
        isDelay: false,
    });
    const [allData, setAllData] = useState<any>([]);

    const REQUEST_PARAMS = useMemo(() => {
        let query = `${SEARCH_OR_STATUS}page=${params.page}&limit=${PER_PAGE * 2}&${SEARCH_ORDER}`;
        if (params.search) {
            query += `&keyword=${params.search}`;
            const providerusernameIndex = filters?.indexOf("representationName");

            const allFilters =
                providerusernameIndex !== -1
                    ? ["representationName", "providerusername", ...filters?.filter((f) => f !== "representationName")]
                    : filters;

            query += allFilters.map((e) => `&searchColumn[]=${e}`).join("");
        }
        if (params.status === "all") {
        } else if (params.status === "NORMAL") {
            query += `&providerType=NORMAL`;
        } else if (params.status === "BUSINESS") {
            query += `&providerType=BUSINESS`;
        } else if (params.status === "PAY_LATER") {
            query += `&providerType=PAY_LATER`;
        }
        if (params.startDate) {
            query += `&from=${encodeURIComponent(getDateFromData(params.startDate))}`;
        }
        if (params.endDate) {
            query += `&to=${encodeURIComponent(getDateToData(params.endDate))}`;
        }
        query += `&fieldSearchTime=createdAt`;
        return query;
    }, [params, filters]);

    const { data, error, isSuccess, refetch, isLoading, isFetching } = useGetOrdersQuery(REQUEST_PARAMS, {
        refetchOnMountOrArgChange: true,
        skip: false,
    });

    const columns: ColumnsType<DataType> = [
        {
            title: t("transport_method"),
            dataIndex: "orderType",
            key: "orderType",
            align: "center",
            width: 100,
            render: (orderType, record) => {
                return (
                    <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
                        <TransportMethodBadge badgeType={orderType} />
                        {
                            record.isCash &&
                            <TransportMethodBadge badgeType={'isCash'} />
                        }
                    </div>
                );
            },
        },
        {
            title: t("dispatch_date"),
            dataIndex: "createdAt",
            key: "createdAt",
            width: 160,
            align: "center",
            render(value) {
                return (
                    <div className="dispatch_date">
                        <div className="text">{moment(value).isValid() ? moment(value).format("YY.MM.DD hh:mm A") : ""}</div>
                    </div>
                );
            },
        },
        {
            title: "상차 예정시간",
            dataIndex: "expectedStartDate",
            key: "expectedStartDate",
            width: 160,
            align: "center",
            render(value) {
                return (
                    <div className="dispatch_date">
                        <div className="text">{moment(value).isValid() ? moment(value).format("YY.MM.DD hh:mm A") : ""}</div>
                        <div className={`text ${moment(value).isValid() && moment(value)?.isBefore(moment()) ? "text-red" : "text-blue"}`}>
                            {formatDelay(value)}
                        </div>
                    </div>
                );
            },
        },
        {
            title: "대표자 성명/이름",
            dataIndex: "representativeName",
            key: "representativeName",
            width: 130,
            align: "center",
        },
        {
            title: t("company_name"),
            dataIndex: "companyName",
            key: "companyName",
            align: "center",
            width: 120,
        },
        {
            title: t("loading_place"),
            dataIndex: "loadingPlace",
            key: "loadingPlace",
            align: "center",
        },
        {
            title: t("unloading_place"),
            dataIndex: "receiptPlace",
            key: "receiptPlace",
            align: "center",
        },
        {
            title: t("estimated_distance"),
            dataIndex: "estimatedDistance",
            key: "estimatedDistance",
            align: "center",
            width: 110,
            render(value) {
                return <div>{`${value}km`}</div>;
            },
        },
        {
            title: t("estimated_time"),
            dataIndex: "estimatedTime",
            key: "estimatedTime",
            align: "center",
            width: 110,
        },
        {
            title: "화주 등록 운임",
            dataIndex: "freightCost",
            key: "freightCost",
            width: 120,
            align: "center",
            render: (item) => {
                return `${new Intl.NumberFormat().format(Number(item))} ${t("won")}`;
            },
        },
        {
            title: "운송인 운임",
            dataIndex: "carrierPaymentAmount",
            key: "carrierPaymentAmount",
            width: 120,
            align: "center",
            render: (item) => {
                return `${new Intl.NumberFormat().format(Number(item))} ${t("won")}`;
            },
        },
        {
            title: "회원타입",
            key: "providerType",
            width: 100,
            align: "center",
            render: (_, data) => {
                return typeOptions?.[data.provider.type as keyof typeof typeOptions]?.["label"] ?? "";
            },
        },
        {
            title: t("more_information"),
            dataIndex: "id",
            key: "id",
            align: "center",
            width: 100,
            render: (id, data) => (
                <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
                    <a
                        className="more-information"
                        onClick={() => {
                            navigate("/dispatch-cancel-management?id=" + id);
                        }}
                    >
                        {t("more_information")}
                    </a>
                    {/* <div className="wrapper-btn fill" onClick={() => openMemo(data)}>
            <div className="btn-text fill">메모</div>
          </div> */}
                </div>
            ),
        },
    ];

    // const openMemo = (data: DataType) => {
    //     dispatch(
    //         openModal({
    //             template: "handle-memo-modal",
    //             width: "512px",
    //             data: { notes: data?.notes, setInfoDetail: refetch, orderInfo: data },
    //         }),
    //     );
    // }

    const searchFilterOptions = useMemo(
        () => [
            {
                label: "대표자 성명/이름",
                value: "representationName",
            },
            { label: t("company_name"), value: "company" },
            { label: t("loading_place"), value: "loadingPlace" },
            { label: t("unloading_place"), value: "receiptPlace" },
        ],
        [],
    );

    useEffect(() => {
        if (isSuccess) {
            const arr = data?.data.map((item: any) => {
                return {
                    ...item,
                    id: item.id,
                    dispatchDate: item.expectedStartDate,
                    representativeName: item.provider?.representationName ? item.provider?.representationName : "-",
                    companyName: item.provider?.company,
                    receiptPlace: item.receiptPlace,
                    loadingPlace: item.loadingPlace,
                    transitPlaces: item.transitPlaces,
                    additionalEndpoints: item.additionalEndpoints,
                    estimatedDistance: item.estimatedDistance,
                    estimatedTravelTime: item.estimatedTravelTime,
                    estimatedTime: getDurationUpdate(item.expectedStartDate, item.expectedEndDate, item.estimatedTravelTime),
                    startDate: getDateFormat(item.expectedStartDate, "yyyy-MM-DD A hh시 mm분"), //2022-09-24 오전 10시
                    endDate: getDateFormat(item.expectedEndDate, "yyyy-MM-DD A hh시 mm분"), //2022-09-24 오후 10시
                };
            });

            setAllData(arr);
            setMetaData(data?.meta);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data]);

    useEffect(() => {
        if (isSuccessDetailOrder) {
            setMemoText(infoDetail?.notes || '');
        }
    }, [infoDetail])

    useEffect(() => {
        if (error && "status" in error && Number(error?.status) >= 300) {
            dispatch(
                openDialog({
                    type: "info",
                    content: t("have_some_error"),
                    confirmText: t("dialog_btn_confirm"),
                    actionConfirm: () => {
                        dispatch(closeModal());
                    },
                }),
            );
        }
    }, [error]);

    /* ---------------------------------- 시간 빼기 --------------------------------- */
    const subtractTime = (startTimeStr: string, endTimeStr: string) => {
        const startTime = moment(startTimeStr);
        const endTime = moment(endTimeStr);
        var minutes = endTime.diff(startTime, "minutes") || 0;
        if (minutes < 1) {
            return `0${t("minutes")}`;
        } else if (minutes >= 60) {
            var hours = ~~(minutes / 60);
            minutes = minutes % 60;
            return minutes > 0 ? `${hours}${t("hours")} ${minutes}${t("minutes")}` : `${hours}${t("hours")}`;
        }
        return `${minutes}${t("minutes")}`;
    };

    /* --------------------------------- 기간 가져오기 -------------------------------- */
    const getDurationUpdate = (startTimeStr: string, endTimeStr: string, estimatedTravelTime: number) => {
        let second = estimatedTravelTime;
        let hour = Math.floor(second / 3600);
        let min = Math.floor((second % 3600) / 60);
        let duration = `${hour} ${t("hours")} ${min} ${t("minutes")}`;
        if (hour <= 0 && min > 0) {
            duration = `${min} ${t("minutes")}`;
        }

        if (hour <= 0 && min <= 0 && second > 0) {
            duration = `1 ${t("minutes")}`;
        }

        if (hour <= 0 && min <= 0 && second <= 0) {
            duration = subtractTime(startTimeStr, endTimeStr);
        }

        if (hour > 0 && min == 0) {
            duration = `${hour} ${t("hours")}`;
        }
    /*     console.log("duration ", duration);
     */ return duration;
    };

    const getDateFormat = (dateStr: string, format: string = "yyyy.MM.DD") => {
        const date = moment(dateStr);
        if (date.isValid()) {
            return date.format(format).replace("AM", "오전").replace("PM", "오후");
        }
        return "";
    };

    const openInNewTab = (url: string) => {
        window.open(url, "_blank", "open");
    };

    /* ---------------------------------- 오더 취소 --------------------------------- */
    const callCancelOrder = async () => {
        const params = {
            id: infoDetail?.id,
        };
        const res = await cancelOrder(params); //@ts-ignore
        const errMsg = res?.error?.data?.message;
        if (!errMsg) {
            dispatch(
                openDialog({
                    type: "info",
                    content: t("dispatch_has_been_cancelled"),
                    confirmText: t("dialog_btn_confirm"),
                    actionConfirm: () => {
                        dispatch(closeModal());
                        setIsShowDetail(false);
                    },
                }),
            );
        } else {
            dispatch(
                openDialog({
                    type: "info",
                    content: errMsg,
                    confirmText: t("dialog_btn_confirm"),
                    actionConfirm: () => {
                        dispatch(closeModal());
                    },
                }),
            );
        }
    };

    const onCancelDispatchSubmit = () => {
        callCancelOrder();
    };

    const onCancelDispatch = () => {
        dispatch(
            openModal({
                template: "show-confirm-dialog-modal",
                width: "468px",
                data: {
                    dialogTitle: t("confirm_cancel_dispatch"),
                    dialogContent: t("cancel_dispatch_note"),
                },
                handleAction: onCancelDispatchSubmit,
            }),
        );
    };

    /* -------------------------------- 운임 비용 변경 -------------------------------- */
    const onFareModification = () => {
        dispatch(
            openModal({
                template: "show-confirm-dialog-modal",
                width: "468px",
                data: {
                    dialogTitle: t("confirm_amend_the_fare"),
                },
                handleAction: async () => {
                    refetch();
                    await wait(300);
                    infoDetail?.isCash
                        ? !infoDetail?.isCompensation &&
                        dispatch(
                            openModal({
                                template: "update-otherfee-modal",
                                width: "530px",
                                data: infoDetail,
                                handleAction: () => {
                                    handleRefreshDetail();
                                },
                            }),
                        )
                        : dispatch(
                            openModal({
                                template: "show-fare-modification-modal",
                                width: "530px",
                                data: infoDetail,
                                handleAction: () => {
                                    handleRefreshDetail();
                                },
                            }),
                        );
                },
            }),
        );
    };

    const onDispatchModification = () => {
        // dispatch(
        //   openModal({
        //     template: "show-modify-dispatch-modal",
        //     width: "1080px",
        //     data: infoDetail,
        //     handleAction: () => {
        //       console.log(infoDetail)
        //     },
        //   }),
        // );
        navigate(`/modify-dispatch?id=${idDispatch}&method=${infoDetail?.orderType === "ROUND_TRIP" ? "two-way" : "one-way"}`)
    }

    const handleMemoEdit = () => {
        if (isMemoEditing) {
            setIsMemoEditing(false)
        } else {
            updateDispatch({ id: idDispatch, data: { notes: memoText } })
                .unwrap()
                .then(() => {
                    handleRefreshDetail();
                    dispatch(
                        openDialog({
                            type: "info",
                            content: '저장되었습니다.',
                            confirmText: '확인',
                        }),
                    );
                    setIsMemoEditing(true);
                })
                .catch((error: any) => {
                    dispatch(
                        openDialog({
                            type: "info",
                            content: error?.data?.message,
                            confirmText: '확인',
                        }),
                    );
                });
        }
    }

    const handleMemoEditCancel = () => {
        setMemoText(infoDetail?.notes || '');
        setIsMemoEditing(true);
    }

    return (
        <div className="dispatch-management">
            {!isShowDetail && (
                <NewTable
                    title={'배차 취소 관리'}
                    columns={columns}
                    typeOptions={Object.values(typeOptions)}
                    allData={allData}
                    metaData={metaData}
                    perPage={PER_PAGE * 2}
                    searchFilterOptions={searchFilterOptions}
                    loading={isFetching}
                    params={params}
                    setParams={setParams}
                    filters={filters}
                    setFilters={setFilters}
                    showDateFilter={true}
                />
            )}
            {isShowDetail && (
                <div className="dispatch-management-detail">
                    <div className="title-wrapper">
                        <div className="dispatch-title">{t("dispatch_details")}</div>
                        <div className="dispatch-cost">
                            <div className="freight-cost">
                                <div className="cost-unit">{t("won")}</div>
                                <div className="cost-text">{`${formatMoney(infoDetail?.freightCost)}`}</div>
                                <div className="title-text">화주 등록 운임</div>
                            </div>
                            <div className="freight-cost">
                                <div className="cost-unit">{t("won")}</div>
                                <div className="cost-text">{`${formatMoney(infoDetail?.carrierPaymentAmount)}`}</div>
                                <div className="title-text">운송인 운임</div>
                            </div>
                        </div>
                    </div>
                    <div className="dispatch-detail-content">
                        <div className="step-wrapper">
                            <div className="step-header">
                                <div className="step-title">{t("step_1")}</div>
                                <div className="title-text">{t("goods_vehicle_info")}</div>
                                <TransportMethodBadge badgeType={infoDetail?.orderType} additionalClass={"ml-16"} />
                                {
                                    infoDetail?.isCash &&
                                    <TransportMethodBadge badgeType={'isCash'} additionalClass={"ml-16"} />
                                }
                            </div>
                            <div className="step-body">
                                <div className="part-wrapper">
                                    <div className="item-wrapper">
                                        <div className="item-title">상하차 방법</div>
                                        <div className="item-sub">{infoDetail?.info}</div>
                                    </div>
                                    <div className="item-wrapper">
                                        <div className="item-title">{t("goods_type")}</div>
                                        <div className="item-sub">{infoDetail?.type}</div>
                                    </div>
                                    <div className="item-wrapper">
                                        <div className="item-title">{t("goods_quantity")}</div>
                                        <div className="item-sub">{infoDetail?.quantity}</div>
                                    </div>
                                    <div className="item-wrapper">
                                        <div className="item-title">{t("vehicle_info")}</div>
                                        <div className="item-sub">
                                            {infoDetail?.tonnage} {infoDetail?.vehicleType}
                                        </div>
                                    </div>
                                    <div className="item-wrapper">
                                        <div className="item-title">혼적 여부</div>
                                        <div className="item-sub">{infoDetail?.isCombineGoods ? `해당 / ${infoDetail?.combineGoods}` : "비해당"}</div>
                                    </div>
                                </div>
                                <div className="part-wrapper">
                                    {infoDetail?.docUrls &&
                                        infoDetail?.docUrls?.length > 0 &&
                                        infoDetail?.docUrls.map((it: any, index: number) => {
                                            return (
                                                <div key={index} className="item-wrapper row-wrapper download-btn-wrap ">
                                                    <div>
                                                        <div className="item-title">{t("goods_docs")}</div>
                                                        <div className="item-sub">{truncateFileName(it?.name, 30)}</div>
                                                    </div>
                                                    <div className="download-btn">
                                                        <div
                                                            className="download-text"
                                                            onClick={() => {
                                                                downloadFile(it?.url, it?.name);
                                                            }}
                                                        >
                                                            {t("download")}
                                                        </div>
                                                    </div>
                                                </div>
                                            );
                                        })}
                                    <div className="item-wrapper">
                                        <div className="item-title">{t("requests")}</div>
                                        <div className="item-sub">{infoDetail?.requirement}</div>
                                    </div>
                                    <div className="item-wrapper">
                                        <div className="item-title">{t("packaging_method")}</div>
                                        <div className="item-sub">{infoDetail?.packingMethod}</div>
                                    </div>
                                    <div className="item-wrapper">
                                        <div className="item-title">팩스번호</div>
                                        <div className="item-sub"> {infoDetail?.fax || ""}</div>
                                    </div>{" "}
                                    <div className="item-wrapper">
                                        <div className="item-title">화주 연락처</div>
                                        <div className="item-sub">
                                            {formatPhoneNumber(
                                                infoDetail?.provider?.type === "NORMAL"
                                                    ? infoDetail?.provider?.phoneContact
                                                    : infoDetail?.provider?.picPhone,
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <Divider type="horizontal" style={{ width: "100%", border: "1px solid #E5E5E5" }} />
                        <div className="memo-wrapper">
                            <div className="memo-title">관리자 메모</div>
                            <textarea
                                className="memo-sub"
                                rows={calculateRows(memoText)}
                                cols={100}
                                disabled={isMemoEditing}
                                value={memoText}
                                onChange={(e) => setMemoText(e.currentTarget.value)}
                            />
                            <button className="memo-button fill" onClick={handleMemoEdit}>{isMemoEditing ? '수정' : '완료'}</button>
                            <button className="memo-button" style={{ display: isMemoEditing ? 'none' : 'inline-block', marginLeft: '5px' }} onClick={handleMemoEditCancel}>취소</button>
                        </div>
                        <div className="step-2-3">
                            <div className="part-wrapper">
                                <div className="step-wrapper">
                                    <div className="step-header">
                                        <div className="step-title">{t("step_2")}</div>
                                        <div className="title-text">{t("loading_place_info")}</div>
                                    </div>
                                    <div className="step-body">
                                        <div className="item-wrapper">
                                            <div className="item-title">{t("address")}</div>
                                            <div className="item-sub">
                                                {infoDetail?.loadingPlace} {infoDetail?.detailLoadingPlace}
                                            </div>
                                        </div>
                                        {infoDetail?.transitPlaces &&
                                            infoDetail?.transitPlaces?.length > 0 &&
                                            infoDetail?.transitPlaces.map((it: any, index: number) => {
                                                return (
                                                    <div key={index} className="item-wrapper">
                                                        <div className="item-title">{t("waypoint")}</div>
                                                        <div className="item-sub">
                                                            {it?.fullAddress} {it?.detailAddress}
                                                        </div>
                                                    </div>
                                                );
                                            })}
                                        <div className="item-wrapper">
                                            <div className="item-title">{t("contact")}</div>
                                            <div className="item-sub">{formatPhoneNumber(infoDetail?.contactSender)}</div>
                                        </div>
                                        <div className="item-wrapper">
                                            <div className="item-title">{t("estimated_loading_time")}</div>
                                            <div className="item-sub">
                                                {infoDetail?.isPickUpImmediately
                                                    ? `${getDateFormat(infoDetail?.expectedStartDate, "yyyy-MM-DD")} 바로상차`
                                                    : getDateFormat(infoDetail?.expectedStartDate, "yyyy-MM-DD A hh시 mm분")}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="part-wrapper">
                                <div className="step-wrapper">
                                    <div className="step-header">
                                        <div className="step-title">{t("step_3")}</div>
                                        <div className="title-text">{t("unloading_place_info")}</div>
                                    </div>
                                    <div className="step-body">
                                        <div className="item-wrapper">
                                            <div className="item-title">{t("address")}</div>
                                            <div className="item-sub">
                                                {infoDetail?.receiptPlace} {infoDetail?.detailReceiptPlace}
                                            </div>
                                        </div>
                                        {infoDetail?.additionalEndpoints &&
                                            infoDetail?.additionalEndpoints?.length > 0 &&
                                            infoDetail?.additionalEndpoints.map((it: any, index: number) => {
                                                return (
                                                    <div key={index} className="item-wrapper">
                                                        <div className="item-title">{t("waypoint")}</div>
                                                        <div className="item-sub">
                                                            {it?.fullAddress} {it?.detailAddress}
                                                        </div>
                                                    </div>
                                                );
                                            })}
                                        <div className="item-wrapper">
                                            <div className="item-title">{t("contact")}</div>
                                            <div className="item-sub">{formatPhoneNumber(infoDetail?.contactReceiver)}</div>
                                        </div>
                                        <div className="item-wrapper">
                                            <div className="item-title">{t("estimated_unloading_time")}</div>
                                            <div className="item-sub">
                                                {infoDetail?.isDropDownImmediately
                                                    ? `${getDateFormat(infoDetail?.expectedEndDate, "yyyy-MM-DD")} 바로하차`
                                                    : getDateFormat(infoDetail?.expectedEndDate, "yyyy-MM-DD A hh시 mm분")}
                                            </div>
                                        </div>
                                        <div className="item-wrapper transport-method">
                                            <TransportMethodBadge badgeType={infoDetail?.orderType} additionalClass={"large ml-5"} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="step-wrapper">
                            <div className="step-header">
                                <div className="step-title">{t("step_4")}</div>
                                <div className="title-text">{t("detailed_route")}</div>
                            </div>
                            <div className="step-body">
                                <div className="part-wrapper">
                                    <div className="item-wrapper">
                                        <div className="item-title">{t("view_route")}</div>
                                        <div className="map-route-btn" onClick={() => openInNewTab(infoDetail?.routeUrl)}>
                                            <img className="icon" alt="icon" src={icMap} />
                                            <div className="btn-text">{t("check_kakao_map_route")}</div>
                                        </div>
                                    </div>
                                </div>
                                <div className="part-wrapper middle-wrapper">
                                    <div className="item-wrapper">
                                        <div className="item-title">{t("estimated_distance_01")}</div>
                                        <div className="item-sub">{`${infoDetail?.estimatedDistance}km`}</div>
                                    </div>
                                </div>
                                <div className="part-wrapper">
                                    <div className="item-wrapper">
                                        <div className="item-title">{t("estimated_time_01")}</div>
                                        <div className="item-sub">
                                            {infoDetail?.expectedStartDate &&
                                                infoDetail?.expectedEndDate &&
                                                infoDetail?.estimatedTravelTime &&
                                                getDurationUpdate(
                                                    infoDetail.expectedStartDate,
                                                    infoDetail.expectedEndDate,
                                                    infoDetail.estimatedTravelTime,
                                                )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="dispatch-detail-footer">
                        <div className="wrapper-btn">
                            <div
                                className="btn-text"
                                onClick={() => {
                                    navigate("/dispatch-cancel-management");
                                }}
                            >
                                {t("list")}
                            </div>
                        </div>
                        {/* <div className="wrapper-btn" onClick={onCancelDispatch}>
                            <div className="btn-text">{t("dispatch_cancellation")}</div>
                        </div>
                        <div className="wrapper-btn fill" onClick={onDispatchModification}>
                            <div className="btn-text fill">배차 수정</div>
                        </div>
                        <div className="wrapper-btn fill" onClick={onFareModification}>
                            <div className="btn-text fill">{t("fare_modification")}</div>
                        </div> */}
                    </div>
                </div>
            )}
        </div>
    );
}