import { DatePicker, DatePickerProps } from "antd";
import "./sale-graph-style.scss";
import { useEffect, useMemo, useState } from "react";
import { Moment } from "moment-timezone";
import { useTranslation } from "react-i18next";
import SalesGraphPayment from "./components/SalesGraphPayment";
import SalesGraphSettlement from "./components/SalesGraphSettlement";
import { Line, LineConfig } from "@ant-design/plots";
import { useGetOrdersGraphQuery } from "./settlementApi";

enum EType {
  PAYMENT = "shipper",
  SETTLEMENT = "carrier",
}

export type MemberType = "ALL" | "NORMAL" | "BUSINESS" | "PAY_LATER";


export interface IParams {
  page: number;
  limit: number;
  from?: string | Moment;
  to?: string | Moment;
}

type paramsType = {
  type: EType;
  providerType?: string;
}

export default function SalesGraph() {
  const { t } = useTranslation();
  const filterTypes = [
    {
      label: t("sales_graph_type_payment"),
      value: EType.PAYMENT,
    },
    {
      label: t("sales_graph_type_settlement"),
      value: EType.SETTLEMENT,
    },
  ];

  const [activeTab, setActiveTab] = useState<EType>(EType.PAYMENT);
  const [startDate, setStartDate] = useState<Moment | null>(null);
  const [endDate, setEndDate] = useState<Moment | null>(null);
  const [dataGraphs, setDataGraphs] = useState<any[]>([]);
  const [memberType, setMemberType] = useState<MemberType>('ALL');
  const [params, setParams] = useState<paramsType>({
    type: activeTab,
  });


  /* --------------------------------- 시작 일 변경 -------------------------------- */
  const onChangeStartDate: DatePickerProps["onChange"] = (date: Moment | null) => {
    setStartDate(date);
  };

  /* --------------------------------- 마감 일 변경 -------------------------------- */
  const onChangeEndDate: DatePickerProps["onChange"] = (date: Moment | null) => {
    setEndDate(date);
  };

  const { data, isFetching, isSuccess } = useGetOrdersGraphQuery(
    params,
    {
      refetchOnMountOrArgChange: true,
      skip: false,
    },
  );

  useEffect(() => {
    const newParams = { ...params };
    if (memberType === "ALL") {
      delete newParams?.providerType;
    } else {
      newParams["providerType"] = memberType;
    }
    setParams(newParams);
  }, [memberType])

  useEffect(() => {
    setStartDate(null);
    setEndDate(null);
  }, [activeTab]);

  // 202201 -> 22.1
  // 202211 -> 22.11
  /* ---------------------------------- 시간 양식 --------------------------------- */
  const convertTimeToMonthOfYear = (str: string) => {
    const year = str.slice(0, 4);
    const month = str.slice(4);

    return `${year.slice(2)}.${parseInt(month)}`;
  };

  useEffect(() => {
    if (isSuccess && !isFetching && data) {
      const newDataGraphs = [];

      for (const [monthOfYear, value] of Object.entries(data)) {
        newDataGraphs.push({
          monthOfYear,
          value,
        });
      }
      setDataGraphs(newDataGraphs);
    }
  }, [isSuccess, isFetching, data]);

  /* --------------------------------- 그래프의 구성 -------------------------------- */
  const graphConfig = useMemo(() => {
    const config: LineConfig = {
      data: dataGraphs,
      xField: "monthOfYear",
      yField: "value",
      smooth: true,
      point: {
        size: 5,
        style: {
          lineWidth: 1,
          fillOpacity: 1,
        },
        shape: (_) => {
          return "circle";
        },
      },
      tooltip: {
        customItems(originalItems) {
          const formatArr = originalItems?.map((item) => {
            return { ...item, value: new Intl.NumberFormat().format(Number(item.value)) };
          });

          return formatArr;
        },
      },
      xAxis: {
        nice: true,
        label: {
          formatter: (name) => convertTimeToMonthOfYear(name),
        },
        line: {
          style: {
            stroke: "#e9ecf2",
          },
        },
        tickLine: {
          style: {
            lineWidth: 2,
            stroke: "#e9ecf2",
          },
          length: 5,
        },
        grid: {
          line: {
            style: {
              stroke: "#e9ecf2",
            },
          },
        },
      },
      yAxis: {
        label: {
          formatter: (v) => {
            return `${v}`.replace(/\d{1,3}(?=(\d{3})+$)/g, (s) => `${s},`);
          },
        },
        grid: {
          line: {
            style: {
              stroke: "#e9ecf2",
            },
          },
        },
      },
    };
    return config;
  }, [dataGraphs]);

  return (
    <div id="sales-graph-screen">
      <div className="graph-section">
        <h3 className="title">화주 결제 금액 합계</h3>
        <div className="annotation">단위 : 1 = 1 만원</div>
        <div className="graph">
          <Line {...graphConfig} />
        </div>
      </div>

      <div className="payment-section">
        <div className="filter-section">
          <div className="type">
            {filterTypes.map((type) => (
              <span
                className={`type-item ${activeTab === type.value ? "active" : ""}`}
                key={type.value}
                onClick={() => setActiveTab(type.value)}
              >
                {type.label}
              </span>
            ))}
          </div>

          <div className="date-range">
            <DatePicker
              inputReadOnly={true}
              value={startDate}
              size="middle"
              onChange={onChangeStartDate}
              style={{ width: 212 }}
              placeholder={t("please_select_a_date")}
            />

            <div className="space"></div>

            <DatePicker
              inputReadOnly={true}
              value={endDate}
              size="middle"
              onChange={onChangeEndDate}
              style={{ width: 212 }}
              placeholder={t("please_select_a_date")}
            />
          </div>
        </div>
        <div className="data-table">
          {activeTab === EType.PAYMENT ? (
            <SalesGraphPayment startDate={startDate} endDate={endDate} memberType={memberType} setMemberType={setMemberType} />
          ) : (
            <SalesGraphSettlement startDate={startDate} endDate={endDate} />
          )}
        </div>
      </div>
    </div>
  );
}
