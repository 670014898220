import { Modal } from "antd";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  modalData,
  modalHandleAction,
  modalStatus,
  modalTemplate,
  modalWidth,
} from "../../store/selector/RootSelector";
import { closeModal } from "./modalSlice";
import "./style.scss";
import SearchFilter from "./template/searchFilter";
import ShipperDetail from "./template/shipperDetail";
import CarrierDetail from "./template/carrierDetail";
import SignUpApproval from "./template/signUpApproval";
import ConfirmDialog from "./template/confirmDialog";
import FareModification from "./template/fareModification";
import UpdateMemo from "./template/updateMemo";
import CarrierDetailSettlements from "./template/carrierDetailSettlements";
import SetDate from "./template/setDate";
import ShipperDetailSettlements from "./template/shipperDetailSettlements";
import PopupFilter from "./template/popupFilter";
import ShipperInfo from "./template/shipperInfo";
import ApprovePaylaterRight from "./template/approvePaylaterRight";
import CancelPaylaterRight from "./template/cancelPaylaterRight";
import VehicleManage from "./template/vehicleManage";
import { RegisterCompany } from "./template/registerCompany";
import CarrierDetailSettlement from "./template/carrierDetailSettlement";
import CarrierSettlementDetailModal from "./template/carrierSettlementDetail";
import TransportDetail from "./template/tranportDetail";
import SettlementHistoryOrderDetail from "./template/settlementHistoryOrderDetail";
import PopupAction from "./template/popupAction";
import ProviderDetailModal from "./template/providerDetailModal";
import KakaoSearchAddress from "./template/kakaoSearchAddress";
import ChangeCarrier from "./template/changeCarrier";
import HandleMatchingMemo from "./template/handleMatchingMemo";
import UpdateOtherFee from "./template/updateOtherFee";
import CancelOrder from "./template/cancelOrder";


export default function CustomModal() {
  const isOpen = useSelector(modalStatus);
  const template = useSelector(modalTemplate);
  const data = useSelector(modalData);
  const width = useSelector(modalWidth);
  const handleAction = useSelector(modalHandleAction);
  const dispatch = useDispatch<any>();

  /* ------------------------------ dialog 내용 생성 ------------------------------ */
  const generateContent = (template: string) => {
    switch (template) {
      case "handle-memo-modal":
        return <HandleMatchingMemo data={data} onClose={handleClose} handleAction={handleAction} />;
      case "show-popup-filter-modal":
        return <PopupFilter data={data} onClose={handleClose} handleAction={handleAction} />;
      case "show-search-filter-modal":
        return <SearchFilter data={data} onClose={handleClose} handleAction={handleAction} />;
      case "show-set-date-modal":
        return <SetDate data={data} onClose={handleClose} handleAction={handleAction} />;
      case "show-update-memo-modal":
        return <UpdateMemo data={data} onClose={handleClose} handleAction={handleAction} />;
      case "show-shipper-info-modal":
        return <ShipperInfo data={data} onClose={handleClose} handleAction={handleAction} />;
      case "show-shipper-detail-modal":
        return <ShipperDetail data={data} onClose={handleClose} handleAction={handleAction} />;
      case "show-carrier-detail-modal":
        return <CarrierDetail data={data} onClose={handleClose} handleAction={handleAction} />;
      case "show-new-carrier-detail-settlements-modal":
        return <CarrierSettlementDetailModal data={data} onClose={handleClose} handleAction={handleAction} />;
      case "show-carrier-detail-settlements-modal":
        return <CarrierDetailSettlements data={data} onClose={handleClose} handleAction={handleAction} />;
      case "show-carrier-detail-settlement-modal":
        return <CarrierDetailSettlement data={data} onClose={handleClose} handleAction={handleAction} />;
      case "show-shipper-detail-settlements-modal":
        return <ShipperDetailSettlements data={data} onClose={handleClose} handleAction={handleAction} />;
      case "show-provider-detail-modal":
        return <ProviderDetailModal data={data} onClose={handleClose} handleAction={handleAction} />;
      case "show-sign-up-approval-modal":
        return <SignUpApproval data={data} onClose={handleClose} handleAction={handleAction} />;
      case "show-transport-detail":
        return <TransportDetail data={data} onClose={handleClose} handleAction={handleAction} />;
      case "show-settlement-history-order-detail":
        return <SettlementHistoryOrderDetail data={data} onClose={handleClose} handleAction={handleAction} />;
      case "show-popup-creation-modal":
        return <PopupAction data={data} onClose={handleClose} handleAction={handleAction} />;
      case "show-confirm-dialog-modal":
        return (
          <ConfirmDialog
            data={data}
            onClose={handleClose}
            handleAction={() => {
              dispatch(closeModal());
              handleAction();
            }}
          />
        );
      case "show-fare-modification-modal":
        return (
          <FareModification
            data={data}
            onClose={handleClose}
            handleAction={() => {
              dispatch(closeModal());
              handleAction();
            }}
          />
        );
      case "update-otherfee-modal":
        return (
          <UpdateOtherFee
            data={data}
            onClose={handleClose}
            handleAction={() => {
              dispatch(closeModal());
              handleAction();
            }}
          />
        );
      case "show-approve-paylater-right-modal":
        return (
          <ApprovePaylaterRight
            data={data}
            onClose={handleClose}
            handleAction={() => {
              dispatch(closeModal());
              handleAction();
            }}
          />
        );
      case "show-cancel-paylater-right-modal":
        return (
          <CancelPaylaterRight
            data={data}
            onClose={handleClose}
            handleAction={() => {
              dispatch(closeModal());
              handleAction();
            }}
          />
        );
      case "show-vehicle-manage-modal":
        return (
          <VehicleManage
            data={data}
            onClose={handleClose}
            handleAction={() => {
              dispatch(closeModal());
              handleAction();
            }}
          />
        );
      case "show-register-company-modal":
        return (
          <RegisterCompany
            data={data}
            onClose={handleClose}
            handleAction={() => {
              dispatch(closeModal());
              handleAction();
            }}
          />
        );
      case "change-carrier-modal":
        return (
          <ChangeCarrier
            data={data}
            onClose={handleClose}
            handleAction={() => {
              dispatch(closeModal());
              handleAction();
            }}
          />
        );
      case "cancel-order-modal":
        return (
          <CancelOrder
            data={data}
            onClose={handleClose}
            handleAction={
              () => {
                dispatch(closeModal());
                handleAction();
              }} />
        );
      case "kakao-search-address":
        return (
          <KakaoSearchAddress data={data} handleConfirm={handleConfirm} handleClose={handleClose} />
        );
      default:
        return "";
    }
  };

  /* -------------------------------- dialog 닫기 ------------------------------- */
  const handleClose = () => {
    dispatch(closeModal());
  };

  /* -------------------------------- dialog 확인 ------------------------------- */
  const handleConfirm = () => { };

  return (
    <Modal
      title=""
      open={isOpen}
      onOk={handleConfirm}
      onCancel={handleClose}
      closable={false}
      okText="Confirm"
      cancelText="Cancel"
      footer={null}
      centered={true}
      className="custom-modal"
      destroyOnClose
      width={width}
    >
      {generateContent(template)}
    </Modal>
  );
}
