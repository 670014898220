import { FC, useMemo } from "react";
import "./style.scss";
import { useTranslation } from "react-i18next";
import { formatNumberToCurrency } from "../../utils/utils";

interface Props {
  data: any;
  fromPage?: "MATCHING_MANAGEMENT" | string;
}

export const CarrierFreightCost: FC<Props> = ({ data, fromPage }) => {
  const { t } = useTranslation();

  const isMP = fromPage === "MATCHING_MANAGEMENT";
  return (
    <div className="container-wrap">
      <div className="card-header">
        <div className="header-title">{t("carrier_freight_cost")}</div>
      </div>
      <div className="freight-cost-content">
        <div className="cost-item">
          <div className="cost-title">화주등록운임</div>
          <div className="cost-value">
            {formatNumberToCurrency(data?.freightCost)}원
          </div>
        </div>
        <div className="cost-item">
          <div className="cost-title">배차킹수수료</div>
          <div className="cost-value">
            {data?.isCash
              ? formatNumberToCurrency(data?.excludingFee || 0)
              : formatNumberToCurrency(data?.freightCost - (data?.carrierPaymentAmount - data?.carrierOtherFee))}{" "}
            원
          </div>
        </div>
        {/* <div className="cost-item">
        <div className="cost-title">{t('excluding_accident_insurance')}</div>
        <div className="cost-value">{formatNumberToCurrency(data?.laborUnemploymentInsurance)} {t('won')}</div>
      </div> */}
        {isMP && data?.carrierOtherFee && Number(data?.carrierOtherFee) > 0 ? (
          <div className="cost-item">
            <div className="cost-title">추가 결제</div>
            <div className="cost-value">
              {formatNumberToCurrency(data?.carrierOtherFee)}원
            </div>
          </div>
        ) : (
          ""
        )}
        <div className="cost-item">
          <div className="final-cost-title">운송인앱 노출 금액</div>
          <div className="final-cost">
            {
              data?.isCash
                ? formatNumberToCurrency(data?.freightCost)
                : formatNumberToCurrency(data?.carrierPaymentAmount)
            }
            원
          </div>
        </div>
        {
          data?.isCash && (
            <div className="cost-item">
              <div className="final-cost-title">운송인앱 노출 수수료</div>
              <div className="final-cost">
                {formatNumberToCurrency(data?.excludingFee)}원
              </div>
            </div>
          )
        }
        {
          (data?.isCash && data?.otherFee !== null) && (
            <div className="cost-item">
              <div className="final-cost-title">운송인 추가 운임</div>
              <div className="final-cost">
                {formatNumberToCurrency(data?.otherFee)}원
              </div>
            </div>
          )
        }
      </div>
    </div>
  );
};
